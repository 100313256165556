import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Divider, Row, Col, Table, Spin } from "antd";
import { MdClose } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { getTradieProfileDetails } from "../../helpers";
import { useContentContext } from "../../providers/ContentContext";
import VerificationChange from "./VerificationChange";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewVerifyTradie = ({ open, onClose, type, tradie, getTradieData }) => {
  let { openNotification, handleError } = useContentContext();

  const [tradieProfileData, setTradieProfileData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  const [categoryDataSource, setCategoryDataSource] = useState([]);
  const [stateDataSource, setStateDataSource] = useState([]);

  const [VerificatStatus, setVerificatStatus] = useState(false);
  const [OpenVerificationChange, setOpenVerificationChange] = useState(false);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setDataLoading(true);

    try {
      let res = await getTradieProfileDetails({
        trady_id: tradie?.trady_id,
        is_admin_panel: 1,
        verification_id: tradie?.request_id,
      })
        .then((response) => {
          if (response?.data.success) {
            setTradieProfileData(response?.data.output);
            return response?.data.output;
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      setCategoryDataSource(
        await res?.job_categories.map((categoryItem, index) => {
          return {
            key: (index + 1).toString(),
            category: categoryItem.job_category,
            jobtype: (
              <div>
                {categoryItem.job_type.map((jobTypeItem) => (
                  <p key={jobTypeItem.trady_job_type_id.toString()}>
                    {jobTypeItem.job_type}
                  </p>
                ))}
              </div>
            ),
          };
        })
      );

      setStateDataSource(
        await res?.states.map((stateItem, index) => {
          return {
            key: (index + 1).toString(),
            state: stateItem.state,
            suburb: (
              <div>
                {stateItem.suburb.map((suburbItem) => (
                  <p key={suburbItem.suburb_id.toString()}>
                    {suburbItem.suburb}
                  </p>
                ))}
              </div>
            ),
          };
        })
      );
    } catch (error) {
      openNotification("error", error);
    }
    setDataLoading(false);
  };

  const openDialogBox = (data) => {
    setOpenVerificationChange(true);
    setVerificatStatus(data);
  };

  const closeDialogBox = () => {
    setOpenVerificationChange(false);
  };

  return (
    <>
      <Drawer open={open} onClose={onClose} width={"large"}>
        <div className="w-full md:w-[768px]">
          {dataLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <Form layout="vertical" className="w-full view-form">
              <div className="relative bg-secondaryWhite p-7 ">
                <div className="w-full flex flex-row  justify-between">
                  <p className="text-[18px] font-semibold">Tasker Details</p>

                  <Button
                    className="border-none"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <MdClose className="text-[20px]" />
                  </Button>
                </div>

                <Form.Item label="Name">
                  <p className="text-[16px] font-medium">
                    {decryptSecureData(tradieProfileData?.full_name)}
                  </p>
                </Form.Item>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">Address</p>

                <Row gutter={16}>
                  <Col xs={24} sm={8}>
                    <Form.Item label="Street Address 1">
                      <p className="text-[16px] font-medium">
                        {decryptSecureData(tradieProfileData?.street_address)}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item label="Street Address 2">
                      <p className="text-[16px] font-medium">
                        {decryptSecureData(tradieProfileData?.apt)}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      label={
                        tradieProfileData?.country === "Sri Lanka"
                          ? "City"
                          : "Suburb"
                      }
                    >
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.suberb}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      label={
                        tradieProfileData?.country === "Sri Lanka"
                          ? "District"
                          : "State"
                      }
                    >
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.state}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item label="Postal Code">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.postal_code}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item label="Country">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.country}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">Contact</p>

                <Row>
                  <Col xs={24} md={12} className="pb-3">
                    <Row className="flex flex-row">
                      <Col xs={5}>
                        <FiPhoneCall className="text-[30px] text-[primaryLight] mt-3" />
                      </Col>
                      <Col xs={18}>
                        <p className="text-[16px] font-normal">Telephone</p>
                        <p className="text-[16px] font-medium">
                          {decryptSecureData(tradieProfileData?.mobile_number)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row className="flex flex-row">
                      <Col xs={5}>
                        <MdOutlineMailOutline className="text-[35px] text-[primaryLight] mt-2" />
                      </Col>
                      <Col xs={18}>
                        <p className="text-[16px] font-normal">Mail</p>
                        <p className="text-[16px] font-medium">
                          {decryptSecureData(tradieProfileData?.email)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">
                  Service Details
                </p>
                <Table
                  className="w-full pb-10"
                  columns={detailColumns}
                  dataSource={categoryDataSource}
                  pagination={false}
                />

                <p className="text-[16px] font-semibold pb-3">
                  Service Suburbs
                </p>
                <Table
                  className="w-full"
                  columns={
                    tradieProfileData?.country === "Sri Lanka"
                      ? districtColumns
                      : suburbColumns
                  }
                  dataSource={stateDataSource}
                  pagination={false}
                />

                <Row gutter={16} className="pt-10">
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-medium pb-3">Subscription</p>
                    <p className="text-[16px] font-normal">
                      {tradieProfileData?.has_subscribed === 1
                        ? "Subscribed"
                        : "Not subscribed"}
                    </p>
                  </Col>
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-medium pb-3">
                      Subscription details
                    </p>
                    <p className="text-[16px] font-normal">
                      {`Subscribed date - ${tradieProfileData?.joined_at}`}
                    </p>
                  </Col>
                </Row>

                <Divider />
                <Row gutter={16} className="pt-10">
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-medium pb-3">
                      Verification Status
                    </p>
                    <p className="text-[16px] font-normal">
                      {tradieProfileData?.has_verified === 1
                        ? "Pending"
                        : tradieProfileData?.has_verified === 2
                        ? "Verified"
                        : tradieProfileData?.has_verified === 3
                        ? "In hold"
                        : tradieProfileData?.has_verified === 4
                        ? "Rejected"
                        : ""}
                    </p>
                  </Col>
                </Row>

                <Divider />
                <div>
                  {type === 1 ? (
                    <Row
                      className="flex flex-row justify-between w-full"
                      gutter={16}
                    >
                      <Col xs={8} className="w-full">
                        <Button
                          type="primary"
                          className="w-full"
                          size="large"
                          onClick={() => {
                            openDialogBox(2);
                          }}
                        >
                          VERIFY
                        </Button>
                      </Col>
                      <Col xs={8} className="w-full outline-button">
                        <Button
                          className="w-full font-medium"
                          size="large"
                          onClick={() => {
                            openDialogBox(3);
                          }}
                        >
                          HOLD
                        </Button>
                      </Col>
                      <Col xs={8} className="w-full">
                        <Button
                          className="w-full font-medium !bg-primaryRed text-white !border-primaryRed hover:!text-white"
                          size="large"
                          onClick={() => {
                            openDialogBox(4);
                          }}
                        >
                          REJECT
                        </Button>
                      </Col>
                    </Row>
                  ) : type === 2 ? (
                    <Row
                      className="flex flex-row justify-between w-full"
                      gutter={16}
                    >
                      <Col xs={12} className="w-full">
                        <Button
                          className="w-full font-medium !bg-primaryRed text-white !border-primaryRed hover:!text-white"
                          size="large"
                          onClick={() => {
                            openDialogBox(4);
                          }}
                        >
                          DENIAL VERIFACATION
                        </Button>
                      </Col>
                      <Col xs={12} className="w-full outline-button">
                        <Button
                          className="w-full font-medium"
                          size="large"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  ) : type === 3 ? (
                    <Row
                      className="flex flex-row justify-between w-full"
                      gutter={16}
                    >
                      <Col xs={12} className="w-full">
                        <Button
                          type="primary"
                          className="w-full"
                          size="large"
                          onClick={() => {
                            openDialogBox(2);
                          }}
                        >
                          VERIFY
                        </Button>
                      </Col>
                      <Col xs={12} className="w-full">
                        <Button
                          className="w-full font-medium !bg-primaryRed text-white !border-primaryRed hover:!text-white"
                          size="large"
                          onClick={() => {
                            openDialogBox(4);
                          }}
                        >
                          REJECT
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row
                      className="flex flex-row justify-center w-full"
                      gutter={16}
                    >
                      <Col className="w-full outline-button">
                        <Button
                          className="w-full font-medium"
                          size="large"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Form>
          )}
        </div>
      </Drawer>
      {OpenVerificationChange && (
        <VerificationChange
          open={OpenVerificationChange}
          onCancel={closeDialogBox}
          tradie={tradie}
          VerificatStatus={VerificatStatus}
          getTradieData={getTradieData}
          drawerClose={onClose}
        />
      )}
    </>
  );
};

export default ViewVerifyTradie;

const detailColumns = [
  {
    title: "Job Categories",
    dataIndex: "category",
    key: "0",
  },
  {
    title: "Job Type",
    dataIndex: "jobtype",
    key: "1",
  },
];

const suburbColumns = [
  {
    title: "State",
    dataIndex: "state",
    key: "0",
  },
  {
    title: "Suburbs",
    dataIndex: "suburb",
    key: "1",
  },
];

const districtColumns = [
  {
    title: "District",
    dataIndex: "state",
    key: "0",
  },
  {
    title: "City",
    dataIndex: "suburb",
    key: "1",
  },
];
